dmx.Component('bs4-toasts', {

    attributes: {
        position: { // top, bottom
            type: String,
            default: 'top'
        },

        align: { // left, right
            type: String,
            default: 'right'
        },

        'offset-x': {
            type: Number,
            default: 15
        },

        'offset-y': {
            type: Number,
            default: 15
        },

        'min-width': {
            type: Number,
            default: 240
        },

        'max-width': {
            type: Number,
            default: 350
        },

        'z-index': {
            type: Number,
            default: 10000
        },

        delay: {
            type: Number,
            default: 5000
        }
    },

    methods: {
        clear: function() {
            this.clear();
        },

        show: function(options) {
            this.show(options);
        }
    },

    render: function(node) {
        this.update({});
    },

    update: function(props) {
        if (!dmx.equal(props, this.props)) {
            dmx.bs4toast.setup(this.props);
        }
    },

    clear: function() {
        dmx.bs4toast.clear();
    },

    show: function(options) { // options: title, message, delay, closable
        dmx.bs4toast.show(options);
    }

});