dmx.Actions({

    'toast.setup': function(options) {
        dmx.bs4toast.setup(this.parse(options));
    },

    'toast.show': function(options) {
        dmx.bs4toast.show(this.parse(options));
    },

    'toast.clear': function() {
        dmx.bs4toast.clear();
    }

});