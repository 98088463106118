dmx.bs4toast = {
    defaults: {
        iconType: 'image',
        position: 'top',
        align: 'right',
        'offset-x': 15,
        'offset-y': 15,
        'z-index': 10000,
        'min-width': 240,
        'max-width': 350,
        animation: true,
        autohide: true,
        closable: true,
        delay: 5000
    },

    container: null,

    setup: function(options) {
        options = Object.assign(dmx.bs4toast.defaults, options);

        if (!dmx.bs4toast.container) {
            dmx.bs4toast.container = document.createElement('div');
            document.body.appendChild(dmx.bs4toast.container);
        }

        var container = dmx.bs4toast.container;
        container.style.removeProperty('top');
        container.style.removeProperty('left');
        container.style.removeProperty('right');
        container.style.removeProperty('bottom');
        container.style.removeProperty('max-width');
        container.style.setProperty('position', 'fixed');
        container.style.setProperty('z-index', options['z-index']);
        container.style.setProperty(options.align, options['offset-x'] + 'px');
        container.style.setProperty(options.position, options['offset-y'] + 'px');
        if (options['max-width']) {
            container.style.setProperty('max-width', options['max-width'] + 'px');
        }
    },

    clear: function() {
        if (dmx.bs4toast.container) {
            dmx.bs4toast.container.textContent = '';
        }
    },

    show: function(options) {
        options = Object.assign({}, dmx.bs4toast.defaults, options);

        if (!dmx.bs4toast.container) {
            dmx.bs4toast.setup();
        }

        var node = dmx.bs4toast.create(options);
        dmx.bs4toast.container.appendChild(node);

        $(node).toast(options).toast('show').on('hidden.bs.toast', function() {
            dmx.bs4toast.container.removeChild(node);
        });
    },

    create: function(options) {
        var html = '<div class="toast"' + (options['min-width'] ? ' style="min-width: ' + options['min-width'] + 'px"' : '') + ' role="alert" aria-live="assertive" aria-atomic="true" data-delay="' + (options.delay || this.props.delay) + '">';
        if (options.icon || options.title || options.subtitle || options.closable) {
            html += '<div class="toast-header">';
            if (options.icon) {
                switch (options.iconType) {
                    case 'fontawesome':
                        html += '<i class="' + options.icon + ' mr-2"></i>';
                        break;
                    default:
                        html += '<img src="' + options.icon + '" height="20" class="rounded mr-2" alt="' + (options.alt || '') + '">';
                        break;
                }
            }
            html += '<strong class="mr-auto">' + (options.title || '') + '</strong>';
            if (options.subtitle) {
                html += '<small class="text-muted">' + (options.subtitle) + '</small>';
            }
            if (options.closable) {
                html += '<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">';
                html += '<span aria-hidden="true">&times;</span>';
                html += '</button>';
            }
            html += '</div>';
        }
        html += '<div class="toast-body">' + (options.message || '') + '</div>';
        html += '</div>';

        var tmp = document.createElement('div');
        tmp.innerHTML = html;
        return tmp.firstChild;
    }
};